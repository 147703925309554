<template>
  <div
    class="
      aqc-container
      border-radius-6
      box-shadow-light-grey
      padding-20
      height-calc-type1
    "
  >
    <dy-table
      :dataList="dataList"
      :rowList="rowList"
      :loading="loading"
      height="calc(100vh - 280px)"
    >
      <template #filter>
        <el-button type="primary" @click="addLink">添加关联</el-button>
        <el-button @click="initData" style="margin-right: 10px"
          >刷新数据</el-button
        >
        <el-select
          v-model="currentOfficial"
          placeholder="请选择公众号"
          @change="handleOfficialChange"
          style="width: 140px"
          class="margin-right-ten"
          clearable
          filterable
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.channelName"
            :value="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <el-select
          v-model="bookId"
          placeholder="书籍"
          filterable
          remote
          clearable
          reserve-keyword
          :remote-method="getBook"
          style="width: 150px"
          class="margin-right-ten"
          @change="handleBookChange"
        >
          <el-option
            v-for="(item, index) in bookList"
            :key="index"
            :label="item.bookName"
            :value="item.id"
          ></el-option>
        </el-select>
        <c-input
          placeholder="账户id"
          v-model="accountId"
          style="width: 200px"
          class="margin-right-ten"
          :onlyNum="true"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </c-input>
        <c-input
          placeholder="账号名称"
          v-model="accountName"
          style="width: 200px"
          class="margin-right-ten"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </c-input>
        <el-tag type="">共{{ total }}条</el-tag>
      </template>
      <template #operation="{ row }">
        <el-button
          class="button-small"
          size="small"
          type="primary"
          @click="changeLink(row)"
          >修改关联</el-button
        >
        <!-- <el-button
          type="primary"
          class="button-small"
          size="small"
          @click="editItem(row)"
          >编辑</el-button
        > -->
      </template>
      <template #status="{ row }">
        <el-tag
          :type="getTagType(row).type"
          @click="handleUnbind(row)"
          :style="getTagStyle(row)"
          >{{ getTagType(row).text }}</el-tag
        >
      </template>
    </dy-table>
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-drawer
      :title="`${isEdit ? curInfo.channelName : '新增'}-关联`"
      :visible.sync="showDetail"
      direction="rtl"
      size="600px"
      append-to-body
      :before-close="handleDialogClose"
      :destroy-on-close="true"
      :show-close="true"
      :wrapperClosable="true"
    >
      <el-form label-width="130px">
        <el-form-item label="账户">
          <el-select
            class="margin-right-twentyFour"
            style="width: 400px"
            v-model="chooseAccountList"
            multiple
            filterable
            :disabled="isSingle"
            :filter-method="handleFilter"
            placeholder="选择账户"
          >
            <el-option
              v-for="item in targetAccountList"
              :key="item.id"
              :value="item.id"
              :label="item.accountName"
            >
              <span style="float: left">{{ item.accountName }}</span>
              <el-tag
                type="info"
                style="float: left; margin-left: 10px; margin-top: 5px"
                >{{ item.accountId }}</el-tag
              >
              <span
                :style="
                  item.status === 1
                    ? 'margin-left: 10px;color:#00bf8a'
                    : item.status === 2
                    ? 'margin-left: 10px;color:#fd5d5a'
                    : 'margin-left: 10px;color:#fd5d'
                "
                >{{ item.status === 1 ? "绑定" : "解绑" }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公众号">
          <el-select
            class="margin-right-twentyFour"
            style="width: 400px"
            v-model="relationForm.accountChannelId"
            filterable
            placeholder="公众号"
          >
            <el-option
              v-for="item in officialList.filter((item) => item.status !== 0)"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                :style="
                  item.status === 1
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status === 1 ? "绑定" : "解绑" }}</span
              >
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="书籍名称">
          <el-select
            v-model="relationForm.booksId"
            filterable
            remote
            clearable
            @clear="getBook"
            style="width: 400px"
            reserve-keyword
            placeholder="书籍名称"
            :remote-method="getBook"
          >
            <el-option
              v-for="item in bookList"
              :key="item.id"
              :label="item.bookName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button
          type="primary"
          style="margin-left: 130px"
          :loading="loadingButton"
          @click="handleSubmitRelation"
          >确 定</el-button
        >
        <el-button @click="handleDialogClose">取 消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getADQList,
  checkADQList,
  bindAccount,
  unbindAd,
  getBookList
} from '@/api/account'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      dataList: [],
      bookId: null,

      rowList: [
        {
          label: 'id',
          prop: 'id',
          width: 50
        },
        {
          label: '账户id',
          prop: 'accountId',
          width: '100'
        },
        {
          label: '账户名',
          prop: 'accountName'
        },
        {
          label: '公众号',
          prop: 'channelName'
        },
        {
          label: '书籍',
          prop: 'bookName'
        },
        {
          label: '状态',
          prop: 'status',
          slot: true
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
        {
          label: '操作',
          action: 'operation'
        }
      ],
      curInfo: {},
      accountName: '',
      currentOfficial: null,
      accountId: null,
      page: 1,
      loading: false,
      pageSize: 15,
      bookList: [],
      total: 0,
      showDetail: false,
      relationForm: {},
      loadingButton: false,
      chooseAccountList: [],
      isSingle: false,
      isEdit: false,
      targetAccountList: [],
      copyTargetAccountList: []
    }
  },
  mounted () {
    this.initData()
  },
  computed: {
    channelList () {
      const { officialList } = this
      return officialList.filter((item) => item.status === 1)
    },
    ...mapGetters(['officialList'])
  },
  methods: {
    initData () {
      this.getBookName()
      getADQList({
        page: this.page,
        pageSize: this.pageSize,
        accountName: this.accountName,
        accountChannelId: this.currentOfficial,
        bookId: this.bookId,
        accountId: this.accountId
      }).then((res) => {
        this.dataList = res.list
        this.total = res.total
      })
    },
    getBookName () {
      getBookList({
        page: 1,
        pageSize: 50
      }).then((res) => {
        this.bookList = res.list
      })
    },
    getBook (bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName
      }).then((res) => {
        this.bookList = res.list
      })
    },
    getBookById (booksIds) {
      return new Promise((resolve) => {
        getBookList({
          page: 1,
          pageSize: 50,
          booksIds
        }).then((res) => {
          this.bookList = res.list
          resolve()
        })
      })
    },
    handleOfficialChange () {
      this.initData()
    },
    handleBookChange () {
      this.initData()
    },
    changeLink (row) {
      this.curInfo = row
      checkADQList().then((res) => {
        this.targetAccountList = res
        this.copyTargetAccountList = res
        this.isSingle = true
        this.chooseAccountList = [
          this.targetAccountList.find((item) => item.id === this.curInfo.id).id
        ]
        if (this.curInfo.accountChannelId) {
          this.$set(
            this.relationForm,
            'accountChannelId',
            this.officialList.find(
              (item) => item.id === this.curInfo.accountChannelId
            ).id
          )
        }
        if (this.curInfo.bookId) {
          this.getBookById(this.curInfo.bookId).then(() => {
            // ! 用set 不然 改变书籍之后页面不更新显示
            this.$set(this.relationForm, 'booksId', this.bookList[0].id)
          })
        }
        this.showDetail = true
        this.isEdit = true
      })
    },
    addLink () {
      checkADQList().then((res) => {
        this.isSingle = false
        this.isEdit = false
        this.targetAccountList = res
        this.copyTargetAccountList = res
        this.showDetail = true
      })
    },
    getTagType (row) {
      if (row.status === 0) {
        return {
          type: 'danger',
          text: '未绑定'
        }
      }
      if (row.status === 1) {
        return {
          type: '',
          text: '绑定'
        }
      }
      if (row.status === 2) {
        return {
          type: 'warning',
          text: '解绑'
        }
      }
    },
    handleDialogClose () {
      this.showDetail = false
      this.resetData()
    },
    handleSubmitRelation () {
      if (!this.relationForm.booksId) {
        return this.$message.warning('请选择书籍！')
      }
      if (!this.relationForm.accountChannelId) {
        return this.$message.warning('请选择公众号!')
      }
      if (this.isEdit) {
        bindAccount([
          {
            id: this.curInfo.id,
            accountChannelId: this.relationForm.accountChannelId,
            bookId: this.relationForm.booksId,
            accessToken: this.curInfo.accessToken,
            marketingId: this.curInfo.marketingId,
            accountId: this.curInfo.accountId,
            appid: this.officialList.find(
              (item) => item.id === this.relationForm.accountChannelId
            ).appid
          }
        ]).then(() => {
          this.$message.success('修改成功!')
          this.handleDialogClose()
          this.resetData()
          this.initData()
        })
      } else {
        const arr = []
        const appid = this.officialList.find(
          (item) => item.id === this.relationForm.accountChannelId
        ).appid
        this.targetAccountList.forEach((item) => {
          if (this.chooseAccountList.find((val) => val === item.id)) {
            arr.push({
              marketingId: item.marketingId,
              accessToken: item.accessToken,
              id: item.id,
              accountId: item.accountId
            })
          }
        })
        arr.forEach((item) => {
          item.accountChannelId = this.relationForm.accountChannelId
          item.bookId = this.relationForm.booksId
          item.appid = appid
        })
        bindAccount(arr).then(() => {
          this.$message.success('新增成功!')
          this.resetData()
          this.handleDialogClose()
          this.initData()
        })
      }
    },
    // !修改解绑状态
    handleUnbind (row) {
      if (row.status !== 1) return
      this.$confirm('确定要解绑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          unbindAd(row.marketingId).then(() => {
            this.$message.success('解绑成功')
            this.initData()
          })
        })
        .catch(() => {})
    },
    handleFilter (val) {
      if (!val) {
        this.targetAccountList = JSON.parse(
          JSON.stringify(this.copyTargetAccountList)
        )
        return
      }
      const regExp = /[,，]+/gi
      if (regExp.test(val)) {
        // ! 如果是通过逗号分割，则判断用户是通过复制来进行的筛选
        const diviValue = val.split(regExp).map(item => {
          item = item.trim()
          return item
        })
        this.targetAccountList = this.copyTargetAccountList.filter((item) => {
          return diviValue.includes(`${item.accountId}`)
        })
        this.chooseAccountList = this.targetAccountList.map((item) => item.id)
        return
      }

      let isNumber = false
      // ! 判断输入的字符每一位是否都是数字 排除NAN的影响
      isNumber = val
        .split('')
        .map((item) => parseInt(item))
        .every((item) => typeof item === 'number' && !isNaN(item))
      if (!isNumber) {
        this.targetAccountList = this.copyTargetAccountList.filter((item) =>
          item.accountName.includes(val)
        )
      } else {
        this.targetAccountList = this.copyTargetAccountList.filter((item) =>
          `${item.accountId}`.includes(val)
        )
      }
    },
    getTagStyle ({ status }) {
      if (status === 1) {
        return {
          cursor: 'pointer'
        }
      }
    },
    handlePageChange (val) {
      this.page = val
      this.initData()
    },
    resetData () {
      this.relationForm = {}
      this.curInfo = {}
      this.isSingle = false
      this.isEdit = false
      this.chooseAccountList = []
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.aqc-container {
  background: #fff;
}
</style>
